body {
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
}
.app {
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
